import PropTypes from 'prop-types';

import { Layout, TableData } from '@utilities';

import NoDataPlaceholder from '../NoDataPlaceholder';

const OpenEnded = ({ data }) => {
  if (!data?.segments?.length) return <NoDataPlaceholder />;

  return (
    <Layout.Flex.Column>
      {data.segments.map((segment) => (
        <TableData
          columns={segment.table.columns}
          data={segment.table.data}
          key={segment.id}
          isPaginated
          size={20}
        />
      ))}
    </Layout.Flex.Column>
  );
};

OpenEnded.propTypes = {
  data: PropTypes.object,
};

export default OpenEnded;
