import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { readRawData } from '@api/survey_dashboard';

import { Error, Layout, Loader, TableData } from '@utilities';

const RawData = ({ surveyId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState();
  const [rawData, setRawData] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const controller = new AbortController();
    const signal = controller.signal;

    try {
      setRawData(await readRawData({ signal, surveyId }));
      setIsLoading(false);
    } catch (error) {
      if (!signal.aborted) {
        setLoadingError(error);
      }
    }
  };

  if (loadingError) return <Error status={loadingError?.response?.status} />;
  if (isLoading) return <Loader isCentered />;

  return (
    <Layout.Flex.Column>
      {rawData?.map((data, index) => (
        <TableData
          columns={data?.table?.columns}
          data={data?.table?.data}
          key={`raw-data-${index}`}
          size={20}
        />
      ))}
    </Layout.Flex.Column>
  );
};

RawData.propTypes = {
  surveyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default RawData;
