import keyBy from 'lodash/keyBy';

import { transformPrompt } from '@src/app/pages/Dashboard/components/Builder/utilities/helpers.js';
/**
 * transforms and formats single people group
 * @param  {Object} people group
 * @return {Object}
 */
export const transformPeopleGroup = (item) => {
  return {
    answers: item?.answers,
    count: item?.count,
    createdDatetime: item?.created_datetime,
    creator: item?.creator,
    description: item?.description,
    folderEntryId: item?.folderentry_id,
    hasException: item?.status === 4,
    id: item?.id,
    isCompleted: item?.status === 3,
    isItem: true,
    isFrozen: item?.is_frozen ?? false,
    isJoin: item?.is_join ?? false,
    isLocked: item?.is_locked ?? false,
    isOwner: !item?.is_locked,
    isPremiumPG: item?.is_premium_pg ?? false,
    isReadOnly: item?.read_only ?? false,
    isShared: item?.is_shared ?? false,
    isSmart: item?.is_smart ?? false,
    lastUpdated: item?.last_updated,
    name: item?.name,
    panel: item?.panel,
    parentId: item?.parent_id,
    refreshDatetime: item?.refresh_datetime,
    status: item?.status,
  };
};

/**
 * transforms and formats single people group folder
 * @param  {Object} people group
 * @return {Object}
 */
export const transformPeopleGroupFolder = (folder) => {
  return {
    createdDatetime: folder?.created_datetime,
    creator: folder?.creator,
    description: folder?.description,
    folderId: folder?.id,
    id: folder?.folderentry_id,
    isFolder: true,
    isLocked: folder?.is_locked ?? false,
    isOwner: !folder?.is_locked,
    isShared: folder?.is_shared ?? false,
    lastUpdated: folder?.last_updated,
    name: folder?.name,
  };
};

/**
 * transforms and formats static groups api
 * @param  {Object} data
 * @return {Object}
 */
export const transformStaticGroups = (data) => {
  return data?.map((staticGroup) => ({
    attributeId: staticGroup?.attributeId,
    id: staticGroup?.id,
    name: staticGroup?.name,
    promptDesc: staticGroup?.prompt_desc,
    type: staticGroup?.type,
  }));
};

/**
 * transforms and formats people group folders
 * @param  {Object} data
 * @return {Object}
 */
export const transformPeopleGroupFolders = (data) => {
  return {
    total: data?.total,
    page: data?.page,
    size: data?.size,
    folders: data?.items?.map((folder) => {
      return transformPeopleGroupFolder(folder);
    }),
  };
};

/**
 * transforms and forms people group prompts
 * @param  {Object} data
 * @return {Object}
 */
export const transformPeopleGroupPrompts = (data) => {
  return {
    ...keyBy(
      data?.prompts?.map((prompt) => transformPrompt(prompt)),
      'id'
    ),
    shopper_group: {
      name: 'Groups',
    },
  };
};

/**
 * transforms and formats people groups
 * @param  {Object} data
 * @return {Object}
 */
export const transformPeopleGroups = (data) => {
  return {
    total: data?.total,
    page: data?.page,
    size: data?.size,
    items: data?.items?.map((item) => {
      return transformPeopleGroup(item);
    }),
  };
};
