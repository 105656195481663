import axios from '@api';

const useMockData = true;

/**
 * read prompt search results
 * @param  {Array} attributeIds   
 * @param  {String} documentId
 * @param  {String} entryId  
 * @param  {String} promptId
 * @param  {Object} searchAnswers
 * @param  {Array} searchPrompts
 * @param  {String} searchQuery 
 * @param  {String} signal AbortController signal
= * @return {Promise}
 */
export const readSearchResults = ({
  attributeIds,
  documentId,
  entryId,
  promptId,
  searchAnswers = {},
  searchPrompts = [],
  searchQuery,
  signal,
}) => {
  const answers = searchPrompts?.flat().includes(promptId) ? JSON.stringify(searchAnswers) : {};
  return axios({
    method: 'get',
    params: {
      answers,
      attributeId: attributeIds.join(','),
      documentId,
      parent_id: entryId,
      search: searchQuery,
    },
    signal,
    url: `/api/prompts/${promptId}/search`,
    withCredentials: true,
  });
};

/**
 * read pinned answers
 * @param  {String} documentId
 * @param  {String} promptId
 * @param  {String} signal
 * @return {Promise}
 */
export const readPinnedAnswers = ({ documentId, promptId, signal }) => {
  if (useMockData) {
    // Mock data
    return {
      data: [
        {
          pinnedAnswerId: 12,
          id: 'sector:isc_apparel',
          name: 'Apparel',
          prompt_desc: 'Apparel',
          attributeId: 'sector',
          type: 9999,
          is_fixed: false,
        },
        {
          pinnedAnswerId: 40,
          id: 'sector:isc_grocery',
          name: 'Grocery',
          prompt_desc: 'Grocery',
          attributeId: 'sector',
          type: 9999,
          is_fixed: false,
        },
      ],
    };
  } else {
    return axios({
      method: 'get',
      params: {
        document_id: documentId,
        prompt_id: promptId,
      },
      signal,
      url: `/v2/api/pinned-answers/`,
      withCredentials: true,
    });
  }
};

/**
 * delete pinned answer
 * @param  {String} pinnedAnswerId
 * @return {Promise}
 */
export const deletePinnedAnswer = ({ pinnedAnswerId }) => {
  return axios({
    method: 'delete',
    url: `/v2/api/pinned-answers/${pinnedAnswerId}`,
    withCredentials: true,
  });
};
