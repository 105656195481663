import merge from 'lodash/merge';

import {
  COLOR_FLYWEIGHT,
  COLOR_SUMO,
  COLOR_WHITE,
  DEFAULT_CHART_COLORS,
} from '../../../helpers/colors';

/**
 * Update chart colors based on existing color map and default colors.
 * @param  {Object} options.seriesColorMap
 * @param  {Array}  options.seriesData
 * @return {Object}
 */
export const updateChartColors = ({ seriesColorMap, seriesData }) => {
  const newSeriesColorMap = {};

  const existingSeriesColorMap = Object.keys(seriesColorMap)
    .filter((key) => seriesData.some((series) => series.name === key))
    .reduce((acc, key) => {
      acc[key] = seriesColorMap[key];
      return acc;
    }, {});

  const usedColors = new Set(Object.values(existingSeriesColorMap));
  const unusedColors = DEFAULT_CHART_COLORS.filter((color) => !usedColors.has(color));

  seriesData.forEach(({ name }) => {
    newSeriesColorMap[name] = seriesColorMap[name] || unusedColors.shift();
  });

  return newSeriesColorMap;
};

/**
 * gets highcharts options with default values
 * @param  {String}  options.chartTitle
 * @param  {Boolean} options.hasLegend
 * @param  {Number}  options.maxHeight
 * @param  {String}  options.type
 * @return {Object}
 */
export const getHighchartsOptions = ({
  chartTitle,
  exportName,
  hasLegend,
  maxHeight,
  setModifiedData = () => {},
  ...options
}) => {
  const defaultOptions = {
    chart: {
      allowMutatingData: false,
      height: maxHeight || 550,
      type: options?.type,
    },
    colors: options?.colors || DEFAULT_CHART_COLORS,
    credits: { enabled: false },
    exporting: {
      chartOptions: {
        legend: {
          enabled: true,
        },
        title: { text: exportName || chartTitle },
      },
      filename: exportName || chartTitle || options?.type,
      sourceWidth: 825,
    },
    legend: {
      enabled: hasLegend ?? true,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    title: {
      text: null,
    },
    tooltip: {
      backgroundColor: COLOR_SUMO,
      borderColor: COLOR_SUMO,
      style: {
        color: COLOR_WHITE,
        fontSize: 13,
      },
    },
    xAxis: {
      gridLineColor: COLOR_FLYWEIGHT,
      tickWidth: 0,
    },
    yAxis: {
      gridLineColor: COLOR_FLYWEIGHT,
      tickWidth: 0,
      title: { text: null },
    },
  };

  const mergedOptions = merge({}, defaultOptions, options);

  if (setModifiedData) {
    setModifiedData(mergedOptions);
  }

  return mergedOptions;
};
