import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { readProfileOptions } from '@api/user';
import { updatePersona } from '@redux/slices/user';

import { Button, HelpIcon, Input, OverlayTriggerTooltip, Select } from '@utilities';

import { proficiencyTooltipContent } from '../../../../utilities/helpers';

import styles from './_index.module.scss';

const Profile = () => {
  const { id, persona } = useSelector((state) => state?.user?.data);

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdatingUser, setIsUpdatingUser] = useState(false);
  const [profile, setProfile] = useState(persona);
  const [profileOptions, setProfileOptions] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await readProfileOptions();
        setProfileOptions(response?.data);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error(
          error,
          `Encountered an issue on trying to fetch profile options or user information`
        );
      }
    };
    getData();
  }, []);

  const applyChanges = async () => {
    setIsUpdatingUser(true);
    try {
      await dispatch(
        updatePersona({
          userId: id,
          persona: {
            discipline: profile?.discipline,
            industry: profile?.industry,
            jobTitle: profile?.jobTitle,
            ...(process.env.REACT_APP_ONBOARDING && { proficiency: profile?.proficiency }),
          },
        })
      );
      setIsUpdatingUser(false);
    } catch (error) {
      console.error(error, `Could not update user's profile.`);
      setIsUpdatingUser(false);
    }
  };

  const isDisabled =
    profile?.jobTitle?.trim() === '' ||
    (persona?.discipline === profile.discipline &&
      persona?.industry === profile.industry &&
      persona?.jobTitle?.trim() === profile?.jobTitle?.trim() &&
      (!process.env.REACT_APP_ONBOARDING || persona?.proficiency === profile.proficiency));

  return (
    <ul className={styles['profile']}>
      <li>
        <Select
          className={styles['profile-select-industry']}
          data-testid="profile-select-industry"
          isLoading={isLoading}
          label="Industry"
          onChange={(option) => setProfile({ ...profile, industry: option?.value })}
          options={profileOptions?.industries}
          placeholder="Select an industry"
          value={{ label: profile?.industry, value: profile?.industry }}
        />
      </li>
      <li>
        <Select
          className={styles['profile-select-discipline']}
          data-testid="profile-select-discipline"
          isLoading={isLoading}
          label="Discipline"
          onChange={(option) => setProfile({ ...profile, discipline: option?.value })}
          options={profileOptions?.disciplines}
          placeholder="Select a discipline"
          value={{ label: profile?.discipline, value: profile?.discipline }}
        />
      </li>
      <li>
        <Input
          data-testid="profile-select-job-title"
          label="Job Title"
          onChange={(jobTitle) => setProfile({ ...profile, jobTitle })}
          placeholder="Enter job title"
          value={profile?.jobTitle || ''}
        />
      </li>
      {process.env.REACT_APP_ONBOARDING && (
        <li>
          <div className={styles['profile-select-label-container']}>
            <label className={styles['profile-select-label']}>Proficiency with Panel Data</label>
            <OverlayTriggerTooltip content={proficiencyTooltipContent} placement="right">
              <HelpIcon />
            </OverlayTriggerTooltip>
          </div>
          <Select
            className={styles['profile-select']}
            isLoading={isLoading}
            onChange={(option) => setProfile({ ...profile, proficiency: option?.value })}
            options={profileOptions?.proficiencies}
            placeholder="Select a proficiency"
            value={{ label: profile?.proficiency, value: profile?.proficiency }}
          />
        </li>
      )}
      <div className={styles['profile-actions']}>
        <hr />
        <div className={styles['profile-actions-toolbar']}>
          <Button
            data-testid="profile-apply-changes"
            isDisabled={isDisabled}
            isLoading={isUpdatingUser}
            onClick={applyChanges}
            text="Save"
          />
        </div>
      </div>
    </ul>
  );
};

export default Profile;
