import chunk from 'lodash/chunk';
import cloneDeep from 'lodash/cloneDeep';
import Papa from 'papaparse';

import { formatData } from '../../../helpers';

/**
 * gets csv output usable in excel/sheets
 * @param  {Object}  cellsById
 * @param  {Object}  cellsSelected
 * @param  {Object}  selectedCell
 * @return {String}  csv output
 */
export const copySelectedCellValues = ({ cellsById, cellsSelected, selectedCell }) => {
  const keys = Object.keys(cellsSelected);

  let text = '';

  if (keys?.length > 0) {
    const firstRowIndex = parseInt(cellsById[keys[0]]?.row?.index);
    const lastRowIndex = parseInt(cellsById[keys[keys.length - 1]]?.row?.index);
    const numberOfRows = lastRowIndex - firstRowIndex + 1;
    const values = keys.map((key) => {
      const rowKeys = key.split('-')[0].split(';');
      let value;
      for (const [rowKeyIndex] of rowKeys.entries()) {
        if (cellsById[key]?.row?.original[rowKeys[rowKeyIndex]] !== 'NAN_SENTINEL') {
          value = cellsById[key]?.row?.original[rowKeys[rowKeyIndex]];
        } else {
          break;
        }
      }

      return formatData({
        format: cellsById[key]?.column?.format,
        value: value || 'NAN_SENTINEL',
      });
    });

    text = Papa.unparse({ data: chunk(values, keys.length / numberOfRows) }, { delimiter: '\t' });
  } else {
    text = formatData({
      format: selectedCell?.column?.format,
      value: selectedCell?.value || 'NAN_SENTINEL',
    });
  }

  return navigator.clipboard.writeText(text);
};

/**
 * gets csv output usable in excel/sheets
 * @param  {Array}  columns
 * @param  {Array}  data
 * @return {String} csv output
 */
export const getCopyTableValue = ({ columns, data }) => {
  const headerGroups = columns.filter((column) => Array.isArray(column?.columns));

  if (headerGroups?.length > 0) {
    columns = [];

    headerGroups.forEach((headerGroup) => {
      columns = columns.concat(headerGroup.columns);
    });
  }

  const footerValues = columns.map((column) => column.Footer);
  const headerAccessors = columns.map((column) => column.accessor);
  const headerNames = columns.map((column) => column.Header);
  const rows = cloneDeep(data);

  columns.forEach((column) => {
    const accessors = column?.accessor.split(';');

    rows.forEach((row) => {
      let value;
      for (const [accessorIndex] of accessors.entries()) {
        if (row?.original[accessors[accessorIndex]] !== 'NAN_SENTINEL') {
          value = row?.original[accessors[accessorIndex]];
        } else {
          break;
        }
      }
      row[column?.accessor] = formatData({
        format: column?.format,
        value: value || 'NAN_SENTINEL',
      });
    });
  });

  const footer = footerValues.some((value) => value)
    ? '\n' + Papa.unparse({ fields: footerValues, data: [] }, { delimiter: '\t', header: true })
    : '';
  const header = Papa.unparse({ fields: headerNames, data: [] }, { delimiter: '\t', header: true });
  const parsedData = Papa.unparse(
    { fields: headerAccessors, data: rows },
    { columns, delimiter: '\t', header: false }
  );

  return header + parsedData + footer;
};

/**
 * gets cell below current cell
 * @param  {Object} options.cellsById
 * @param  {Number} options.rowsLength
 * @param  {Object} options.selectedCell
 * @return {Object}
 */
export const getCellDown = ({ cellsById, rowsLength, selectedCell }) => {
  const columnId = selectedCell?.id?.split('-')[0];
  const rowIndex = selectedCell?.row?.index;
  const updatedRowIndex = rowIndex < rowsLength - 1 ? rowIndex + 1 : rowsLength - 1;
  const updatedRowId = Object.values(cellsById).find((cell) => {
    return cell.row.index === updatedRowIndex;
  })?.row?.id;

  return cellsById[columnId + '-' + updatedRowId];
};

/**
 * gets cell to the left of current cell
 * @param  {Object} options.cellsById
 * @param  {Object} options.selectedCell
 * @return {Object}
 */
export const getCellLeft = ({ cellsById, selectedCell }) => {
  const rowIndex = selectedCell?.row?.index;
  const columnIndex = selectedCell?.row?.cells?.findIndex((cell) => cell?.id === selectedCell?.id);
  const updatedColumnId =
    selectedCell?.row?.cells[columnIndex > 0 ? columnIndex - 1 : 0]?.id?.split('-')[0];
  const updatedRowId = Object.values(cellsById).find((cell) => {
    return cell.row.index === rowIndex;
  })?.row?.id;

  return cellsById[updatedColumnId + '-' + updatedRowId];
};

/**
 * gets cell to the right of current cell
 * @param  {Object} options.cellsById
 * @param  {Object} options.selectedCell
 * @return {Object}
 */
export const getCellRight = ({ cellsById, selectedCell }) => {
  const rowIndex = selectedCell?.row?.index;
  const columnIndex = selectedCell?.row?.cells?.findIndex((cell) => cell?.id === selectedCell?.id);
  const columnsLength = selectedCell?.row?.cells?.length;
  const updatedColumnId =
    selectedCell?.row?.cells[
      columnIndex < columnsLength - 1 ? columnIndex + 1 : columnsLength - 1
    ]?.id?.split('-')[0];
  const updatedRowId = Object.values(cellsById).find((cell) => {
    return cell.row.index === rowIndex;
  })?.row?.id;

  return cellsById[updatedColumnId + '-' + updatedRowId];
};

/**
 * gets cell above current cell
 * @param  {Object} options.cellsById
 * @param  {Object} options.selectedCell
 * @return {Object}
 */
export const getCellUp = ({ cellsById, selectedCell }) => {
  const columnId = selectedCell?.id?.split('-')[0];
  const rowIndex = selectedCell?.row?.index;
  const updatedRowIndex = rowIndex > 0 ? rowIndex - 1 : 0;
  const updatedRowId = Object.values(cellsById).find((cell) => {
    return cell.row.index === updatedRowIndex;
  })?.row?.id;

  return cellsById[columnId + '-' + updatedRowId];
};

/**
 * gets sort type based on format
 * @param  {String} format
 * @return {String}
 */
export const getSortType = (format) => {
  switch (format) {
    case '0.0%':
    case '0,0.0':
    case '0,0.0%':
    case '+0.0':
    case '0.0':
    case '0,0':
    case '$0,0':
    case '$0,0.00':
    case '0.00%':
      return 'numeric';
    default:
      return 'alphanumeric';
  }
};

/**
 * added sort types to react-table defaults
 * @type {Object}
 */
export const sortTypes = {
  numeric: (rowA, rowB, columnId) => {
    let a = rowA.values[columnId];
    let b = rowB.values[columnId];

    if (a === 'NAN_SENTINEL') {
      a = 0;
    }

    if (b === 'NAN_SENTINEL') {
      b = 0;
    }

    return a - b;
  },
};
