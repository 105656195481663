import axios from '@api';

import {
  transformEssayResponses,
  transformGlobalFilters,
  transformRawData,
  transformSurveyResponses,
} from './utilities/helpers';

/**
 * read essay responses
 * @param {Object} options
 * @param {Object} [options.query]
 * @param {Number} [options.signal] AbortController signal
 * @param {Number|String} options.surveyId
 * @return {Promise}
 */
export const readEssayResponses = async ({ query = {}, signal, surveyId }) => {
  return axios({
    method: 'get',
    params: {
      ...Object.keys(query).reduce(
        (acc, key) => ({ ...acc, ...(query[key] && { [key]: query[key] }) }),
        {}
      ),
      survey_id: surveyId,
    },
    signal,
    url: '/v2/api/survey-dashboard/essay-responses/table',
    withCredentials: true,
  }).then(({ data }) => transformEssayResponses(data));
};

/**
 * read global filters
 * @param {Object} options
 * @param {Number} [options.signal] AbortController signal
 * @param {Number|String} options.surveyId
 * @return {Promise}
 */
export const readGlobalFilters = async ({ signal, surveyId }) => {
  return axios({
    method: 'get',
    signal,
    url: `/v2/api/survey-dashboard/global-filters/${surveyId}`,
    withCredentials: true,
  }).then(({ data }) => transformGlobalFilters(data));
};

/**
 * read raw data
 * @param {Object} options
 * @param {Number} [options.signal] AbortController signal
 * @param {Number|String} options.surveyId
 * @return {Promise}
 */
export const readRawData = async ({ signal, surveyId }) => {
  return axios({
    method: 'get',
    params: {
      survey_id: surveyId,
    },
    signal,
    url: '/v2/api/survey-dashboard/raw-data/table',
    withCredentials: true,
  }).then(({ data }) => transformRawData(data));
};

/**
 * read survey responses
 * @param {Object} options
 * @param {Object} [options.query]
 * @param {Number} [options.signal] AbortController signal
 * @param {Number|String} options.surveyId
 * @return {Promise}
 */
export const readSurveyResponses = async ({ query = {}, signal, surveyId }) => {
  return axios({
    method: 'get',
    params: {
      ...Object.keys(query).reduce(
        (acc, key) => ({ ...acc, ...(query[key] && { [key]: query[key] }) }),
        {}
      ),
      survey_id: surveyId,
    },
    signal,
    url: '/v2/api/survey-dashboard/survey-responses/bar-chart',
    withCredentials: true,
  }).then(({ data }) => transformSurveyResponses(data));
};
