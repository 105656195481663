import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { QUESTION_TYPES } from '@api/instant_survey';
import { readEssayResponses, readSurveyResponses } from '@api/survey_dashboard';
import {
  Button,
  DEFAULT_CHART_COLORS,
  Error,
  FilterGroup,
  Layout,
  Legend,
  LoaderSkeleton,
  ResetFilterIcon,
  Select,
  truncateTextWithEllipsis,
} from '@utilities';

import ComparisonCharts from './components/ComparisonCharts';
import OpenEnded from './components/OpenEnded';

import { DISPLAY_AS_OPTIONS } from './utilities/helpers';

import styles from './_index.module.scss';

const Responses = ({ globalFilters, survey }) => {
  const compareByOptions = useMemo(
    () => [
      {
        label: 'Primary Segments',
        options: [
          { label: 'Overall', value: '' },
          ...(survey.groups?.length === 1 ? [] : [{ label: 'Quota Group', value: 'quota_group' }]),
        ],
      },
      {
        label: 'Demographic Segments',
        options: globalFilters.demographicFilters
          ?.filter(({ compareBy }) => compareBy)
          .map(({ id, label }) => ({
            label,
            value: id,
          })),
      },
    ],
    [globalFilters, survey]
  );

  const [compareBy, setCompareBy] = useState(
    survey.groups?.length > 1 ? compareByOptions[0].options[1] : compareByOptions[0].options[0]
  );
  const [displayAs, setDisplayAs] = useState(DISPLAY_AS_OPTIONS[0]);
  const [essayResponses, setEssayResponses] = useState();
  const [surveyResponses, setSurveyResponses] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedQuestionFilters, setSelectedQuestionFilters] = useState({});

  const requestController = useRef(null);

  useEffect(() => {
    getData();
    return () => requestController?.current?.abort();
  }, [compareBy.value, selectedFilters, selectedQuestionFilters]);

  const areAnyFiltersSelected = useMemo(() => {
    return (
      Object.keys(selectedFilters).some((key) =>
        Object.keys(selectedFilters[key]).some((optionKey) => selectedFilters[key][optionKey])
      ) ||
      Object.keys(selectedQuestionFilters).some((key) =>
        Object.keys(selectedQuestionFilters[key]).some(
          (optionKey) => selectedQuestionFilters[key][optionKey]
        )
      )
    );
  }, [selectedFilters, selectedQuestionFilters]);
  const compareByGroups = useMemo(() => {
    switch (compareBy.value) {
      case '':
        return [{ color: DEFAULT_CHART_COLORS[0], id: 'overall', name: 'Overall' }];
      case 'quota_group':
        return survey.groups?.map(({ id, name }, index) => ({
          color: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
          id,
          name,
        }));
      default:
        return (
          globalFilters.demographicFilters
            ?.find(({ id }) => id === compareBy.value)
            ?.compareBy.map(({ id, label }, index) => ({
              color: DEFAULT_CHART_COLORS[index % DEFAULT_CHART_COLORS.length],
              id,
              name: label,
            })) || []
        );
    }
  }, [compareBy]);
  const questionFilters = useMemo(
    () =>
      survey?.questions
        .map((question, index) => ({ ...question, index })) // preserve the pre-filtered indexes
        .filter(({ type }) => type !== QUESTION_TYPES.ESSAY.value)
        .map(({ id, index, answers, text, type }) => ({
          id,
          label: `Q${index + 1}. ${truncateTextWithEllipsis({ length: 16, text })}`,
          options: answers?.map(({ id, text }) => ({
            id,
            label: truncateTextWithEllipsis({ length: 18, text }),
            title: text,
          })),
          title: text,
          type,
        })),
    [survey]
  );

  const getData = async () => {
    const controller = new AbortController();
    requestController?.current?.abort();
    requestController.current = controller;

    const query = {
      compare_by: compareBy?.value,
      option_filter: Object.keys(selectedQuestionFilters)
        .reduce((acc, key) => {
          Object.keys(selectedQuestionFilters[key]).forEach((optionKey) => {
            if (selectedQuestionFilters[key][optionKey]) acc.push(optionKey);
          });

          return acc;
        }, [])
        .join(';'),
      ...Object.keys(selectedFilters).reduce((acc, key) => {
        return {
          ...acc,
          [key]: Object.keys(selectedFilters[key])
            .filter((optionKey) => selectedFilters[key][optionKey])
            .join(';'),
        };
      }, {}),
    };

    setIsLoading(true);
    setLoadingError(null);
    try {
      const requests = [
        readEssayResponses({
          query,
          signal: controller.signal,
          surveyId: survey.id,
        }),
        readSurveyResponses({
          query,
          signal: controller.signal,
          surveyId: survey.id,
        }),
      ];
      const responses = await Promise.all(requests);
      setEssayResponses(responses[0]);
      setSurveyResponses(responses[1]);
      setIsLoading(false);
    } catch (error) {
      if (!controller.signal.aborted) {
        setLoadingError(error);
      }
    }
  };

  const handleResetFilters = () => {
    setSelectedFilters({});
    setSelectedQuestionFilters({});
  };

  if (loadingError) return <Error status={loadingError?.response?.status} />;

  return (
    <Layout.Flex className={styles['responses']} gap="large">
      <Layout.Sidebar className={styles['responses-sidebar']}>
        <Layout.Flex.Column gap="x-large">
          <div className={styles['responses-compare-by']}>
            <Select
              className={styles['responses-filter-select']}
              label="Compare By"
              onChange={setCompareBy}
              options={compareByOptions}
              value={compareBy}
            />
            <Legend items={compareByGroups} />
          </div>

          <Select
            className={styles['responses-filter-select']}
            label="Display Data In"
            onChange={setDisplayAs}
            options={DISPLAY_AS_OPTIONS}
            value={displayAs}
          />

          {globalFilters?.demographicFilters && (
            <Layout.Flex.Column gap="small">
              <h4>Demographic Filters</h4>
              {globalFilters.demographicFilters.map((filter) => (
                <FilterGroup
                  filter={filter}
                  key={filter.id}
                  onChange={(value) =>
                    setSelectedFilters({ ...selectedFilters, [filter.id]: value })
                  }
                  value={selectedFilters[filter.id]}
                />
              ))}
            </Layout.Flex.Column>
          )}

          {survey?.questions.some(({ type }) => type !== QUESTION_TYPES.ESSAY.value) && (
            <Layout.Flex.Column gap="small">
              <h4>Question Filters</h4>
              {questionFilters.map((filter) => (
                <FilterGroup
                  filter={filter}
                  key={filter.id}
                  onChange={(value) =>
                    setSelectedQuestionFilters({
                      ...selectedQuestionFilters,
                      [filter.id]: value,
                    })
                  }
                  value={selectedQuestionFilters[filter.id]}
                />
              ))}
            </Layout.Flex.Column>
          )}

          <Button
            className={styles['responses-filter-reset']}
            icon={<ResetFilterIcon />}
            isDisabled={!areAnyFiltersSelected}
            onClick={handleResetFilters}
            text="Reset Filters"
            variant="secondary"
          />
        </Layout.Flex.Column>
      </Layout.Sidebar>

      <Layout.Fill as={Layout.Flex.Column} className={styles['responses-main']} gap="x-large">
        {survey?.questions.map((question, index) => (
          <Fragment key={question.id}>
            <div className={styles['responses-question']}>
              <h3 className={styles['responses-question-text']}>
                Q{index + 1}. {question.text}
              </h3>
              {isLoading && (
                <LoaderSkeleton height={200}>
                  <rect x="0" y="0" rx="2" ry="2" width="1000" height="200" />
                </LoaderSkeleton>
              )}
              {!isLoading && (
                <>
                  {question.type !== QUESTION_TYPES.ESSAY.value && (
                    <ComparisonCharts
                      data={surveyResponses.find(
                        ({ id, shortName }) => id === question.id || shortName === question.id
                      )}
                      displayAs={displayAs.value}
                      question={question}
                    />
                  )}
                  {question.type === QUESTION_TYPES.ESSAY.value && (
                    <OpenEnded
                      data={essayResponses.find(
                        ({ id, shortName }) => id === question.id || shortName === question.id
                      )}
                    />
                  )}
                </>
              )}
            </div>
            {index !== survey.questions.length - 1 && <hr />}
          </Fragment>
        ))}
      </Layout.Fill>
    </Layout.Flex>
  );
};

Responses.propTypes = {
  globalFilters: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
};

export default Responses;
