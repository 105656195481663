import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import classNames from 'classnames';

import { AsteriskIcon } from '../../icons';

import { getCleaveOptions, getPlaceholder } from './utilities/helpers';

import styles from './_index.module.scss';

const Input = ({
  autoComplete,
  autoFocus,
  className,
  'data-testid': dataTestId,
  error,
  isDisabled,
  isRequired,
  label,
  maxLength,
  onChange,
  onPaste,
  placeholder,
  type,
  value,
}) => {
  const formats = ['decimal', 'dollar', 'percentage'];
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }, []);

  const handleChange = (event) => {
    onChange(event?.target?.rawValue || event?.target?.value);
  };
  const handlePaste = (event) => {
    if (onPaste) {
      const value = (event.clipboardData || window.clipboardData).getData('text');
      onPaste(event, value);
    }
  };

  const otherClassNames = classNames(
    styles['input'],
    {
      [styles['is-percentage']]: type === 'percentage',
      [styles['has-error']]: error,
    },
    className
  );

  const props = {
    className: otherClassNames,
    disabled: isDisabled,
    onChange: handleChange,
    onPaste: handlePaste,
    options: getCleaveOptions(type),
    placeholder: placeholder || getPlaceholder(type),
    value,
  };

  return (
    <div>
      {label && (
        <label className={styles['input-label']}>
          {label}
          {isRequired && <AsteriskIcon />}
        </label>
      )}
      {formats.includes(type) ? (
        <Cleave {...props} />
      ) : (
        <input
          {...props}
          autoComplete={autoComplete}
          data-testid={dataTestId}
          maxLength={maxLength}
          ref={inputRef}
          type={type}
        />
      )}
      {error && <p className={styles['input-error']}>{error}</p>}
    </div>
  );
};

Input.defaultProps = {
  autoFocus: false,
  type: 'text',
  isDisabled: false,
  isRequired: false,
  onChange: () => {},
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  'data-testid': PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  onPaste: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default Input;
