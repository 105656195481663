import PropTypes from 'prop-types';

import { BarChart, formatData, Layout, TableData, tooltipFormatter } from '@utilities';

import { DISPLAY_AS } from '../../utilities/helpers';
import NoDataPlaceholder from '../NoDataPlaceholder';

const ComparisonCharts = ({ data, displayAs = DISPLAY_AS.PERCENT.value, question }) => {
  if (!data) return <NoDataPlaceholder />;

  const chartToDisplay = displayAs === DISPLAY_AS.PERCENT.value ? data?.chartPct : data?.chartCount;
  const tableToDisplay = displayAs === DISPLAY_AS.PERCENT.value ? data?.tablePct : data?.tableCount;

  return (
    <Layout.Flex.Column>
      <BarChart
        chartTitle={`Answer Options by ${
          displayAs === DISPLAY_AS.PERCENT.value ? DISPLAY_AS.PERCENT.label : DISPLAY_AS.COUNT.label
        }`}
        exportName={question.text}
        maxHeight={400}
        series={chartToDisplay?.series}
        tooltip={{
          formatter: function () {
            return tooltipFormatter({ format: chartToDisplay?.format, data: this });
          },
        }}
        xAxis={chartToDisplay?.xAxis}
        yAxis={{
          labels: {
            formatter: function () {
              return formatData({ format: chartToDisplay?.format, value: this.value });
            },
          },
        }}
      />
      <TableData columns={tableToDisplay?.columns} data={tableToDisplay?.data} />
    </Layout.Flex.Column>
  );
};

ComparisonCharts.propTypes = {
  data: PropTypes.object,
  displayAs: PropTypes.string,
  question: PropTypes.object.isRequired,
};

export default ComparisonCharts;
