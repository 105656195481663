import Media from '../components/Media';

/**
 * gets option label based on prompt description
 * @param  {Object} option
 * @return {String|Node}
 */
export const getOptionLabel = (option) => {
  if (option?.question_type === 'MEDIA') {
    return <Media option={option} />;
  }

  let [parent, child] = option?.prompt_desc?.split(' || ') || [undefined, undefined];
  child = child ? child.replace(`(${parent})`, '') : undefined;
  if (child)
    return (
      <>
        {child} <span> &nbsp;- {parent}</span>
      </>
    );

  if (option?.operatorInfo) return option?.metric?.name;

  return option?.name;
};
