import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AsteriskIcon, PillOverlayPopover, useDebounce } from '@utilities';

import SearchOptions from '../SearchOptions';
import styles from './_index.module.scss';

const Item = ({
  attributeId,
  isRequired,
  modalBodyRef,
  pillLabel,
  promptId,
  selectedAreaOfInterest,
  setSelectedAreaOfInterest,
  type,
}) => {
  const [item, setItem] = useState(null);
  const [searchQuery, setSearchQuery, { isDebouncing, signal }] = useDebounce('');

  useEffect(() => {
    setSelectedAreaOfInterest((prevState) => ({
      ...prevState,
      [promptId]: item,
    }));
  }, [item]);

  return (
    <div>
      <label className={styles['item-label']}>
        {type}
        {isRequired && <AsteriskIcon />}
      </label>
      <div className={styles['item-selections']}>
        <PillOverlayPopover
          container={modalBodyRef.current}
          header={`Select a ${pillLabel}`}
          isModified={item ? true : false}
          onClose={() => setItem(null)}
          onHidePopover={() => setSearchQuery('')}
          overlayClassName={styles['item-search-overlay-popover']}
          value={item?.name ? `${pillLabel}: ${item?.name}` : `Select a ${type}`}
        >
          <div className={styles['item-search']}>
            <SearchOptions
              attributeIds={[attributeId]}
              isDebouncing={isDebouncing}
              item={item}
              promptId={promptId}
              searchQuery={searchQuery}
              selectedAreaOfInterest={selectedAreaOfInterest}
              setItem={setItem}
              setSearchQuery={setSearchQuery}
              signal={signal}
            />
          </div>
        </PillOverlayPopover>
      </div>
    </div>
  );
};

PropTypes.defaultProps = {
  isRequired: false,
};

Item.propTypes = {
  attributeId: PropTypes.string,
  isRequired: PropTypes.bool,
  modalBodyRef: PropTypes.object,
  pillLabel: PropTypes.string,
  promptId: PropTypes.string,
  selectedAreaOfInterest: PropTypes.object,
  setSelectedAreaOfInterest: PropTypes.func,
  type: PropTypes.string,
};

export default Item;
