import { useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import Checkbox from '../Checkbox';
import Flex from '../Layout/components/Flex';

import { ChevronBoldIcon } from '../../icons/ui';

import styles from './_index.module.scss';

const FilterGroup = ({ className, filter, isDefaultOpen, onChange, value }) => {
  const [isGroupExpanded, setIsGroupExpanded] = useState(isDefaultOpen);

  const areAllValuesSelected = filter.options?.every(({ id }) => value?.[id]);
  const isAnyValueSelected = filter.options?.some(({ id }) => value?.[id]);

  const toggleAllOptions = () => {
    onChange(filter.options.reduce((acc, { id }) => ({ ...acc, [id]: !isAnyValueSelected }), {}));
  };
  const toggleOption = (optionId) => {
    onChange({ ...value, [optionId]: !value?.[optionId] });
  };

  return (
    <div className={classNames(styles['filter-group'], className)}>
      <Flex gap="small">
        <Checkbox
          id={`filter-group-${filter.id}`}
          isChecked={areAllValuesSelected}
          isPartial={isAnyValueSelected}
          label={filter.label}
          onChange={toggleAllOptions}
          title={filter.title}
          value={filter.id}
        />
        <button
          aria-controls={`filters-${filter.id}`}
          aria-expanded={isGroupExpanded}
          className={styles['filter-group-button']}
          id={`filter_group-${filter.id}_expand`}
          onClick={() => setIsGroupExpanded(!isGroupExpanded)}
        >
          <ChevronBoldIcon />
        </button>
      </Flex>

      <ul
        aria-label={filter.title}
        className={styles['filter-group-list']}
        hidden={!isGroupExpanded}
        id={`filters-${filter.id}`}
        role="region"
      >
        {filter.options?.map((option) => (
          <li key={option.id}>
            <Checkbox
              id={`filter_${filter.id}_${option.id}`}
              isChecked={value?.[option.id]}
              label={option.label}
              onChange={() => toggleOption(option.id)}
              title={option.title}
              value={option.id}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

FilterGroup.defaultProps = {
  isDefaultOpen: false,
};

FilterGroup.propTypes = {
  className: PropTypes.string,
  isDefaultOpen: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};

export default FilterGroup;
