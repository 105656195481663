import cloneDeep from 'lodash/cloneDeep';

import {
  FIELDS_USING_ID,
  MODEL_TYPES,
  PLACEHOLDER_IMAGE,
  QUESTION_LOGIC_OPERATORS,
  SURVEY_HELP_URL,
} from './constants';

/**
 * @typedef {Object} InstantSurvey
 * @property {Group[]} groups
 * @property {Number} [id]
 * @property {String} instantSurveyType
 * @property {?Date} issueDatetime
 * @property {SurveyQuestionLogic} questionLogic
 * @property {Question[]} questions
 * @property {String} status
 * @property {String} title
 */

/**
 * @typedef {Object} Group
 * @property {?Number} expectedCompletes
 * @property {Number|String} id
 * @property {String} name
 * @property {Object[]} promptAnswers
 * @property {Number} quotaLimit
 * @property {?String} validationStatus
 * @property {?Number} validationUniqueUsers
 * @property {?Number} validationUsers
 */

/**
 * @typedef {Object} Logic
 * @property {Object[]} answers
 * @property {LogicOperator} operator
 * @property {Number} [question]
 *
 * @typedef {'ALL'|'ANY'|'NOT'} LogicOperator
 *
 * @typedef {Object} QuestionLogic
 * @property {Logic} [disqualify]
 * @property {Object} [skip]
 * @property {Logic[]} skip.logic
 * @property {LogicOperator} skip.operator
 *
 * @typedef {Object.<number, QuestionLogic>} SurveyQuestionLogic
 */

/**
 * formatInstantSurvey
 * takes an in-application InstantSurvey and formats it for create/update requests
 * @param {InstantSurvey} instantSurvey
 * @return {Object}
 */
export const formatInstantSurvey = ({
  groups,
  id,
  instantSurveyType: instant_survey_type,
  questionLogic: question_logic,
  questions,
  status,
  title,
}) => {
  return {
    groups: groups.map(formatInstantSurveyGroup),
    id,
    instant_survey_type,
    question_logic: formatQuestionLogic(question_logic),
    questions: questions.map(formatQuestion),
    status,
    title,
  };
};

/**
 * formatInstantSurveyGroup
 * prepares an in-app survey group to be sent to the instant survey service
 * @param {Group} group
 * @return {Object}
 */
const formatInstantSurveyGroup = ({
  _delete,
  promptAnswers,
  id,
  name,
  quotaLimit: quota_limit,
}) => ({
  _delete,
  id: typeof id === 'string' ? undefined : id, // remove temporary uids
  prompt_answers: promptAnswers.map(
    ({ _delete, field, fieldType, id, operator, promptId, values }) => {
      let value;
      if (_delete) {
        value = '_delete';
      } else if (fieldType === 'DATE') {
        value = values[0];
      } else {
        value = values
          ?.map(({ id, name }) => (FIELDS_USING_ID.includes(field) ? id : name))
          .join(';');
      }

      return {
        _delete,
        fe_notes: { insights_values: values },
        field,
        field_type: fieldType,
        id,
        operator,
        prompt_id: promptId,
        value,
      };
    }
  ),
  quota_limit,
  title: name,
});

/**
 * @typedef {Object} Question
 * @property {Object[]} answers
 * @property {String} [caption]
 * @property {Number} folder
 * @property {Number} [id]
 * @property {Object} [image]
 * @property {String} [image.imageBucketName]
 * @property {String} [image.imageCdnUrl]
 * @property {String} [image.imageObjectKey]
 * @property {String} [image.imageObjectType]
 * @property {String[]} instantSurveyTypes
 * @property {Boolean} randomizeOptions
 * @property {String} text
 * @property {String} type
 */

/**
 * formatQuestion
 * @param {Question} question
 * @return {Object}
 */
export const formatQuestion = ({
  answers: options,
  image,
  instantSurveyTypes: instant_survey_types,
  randomizeOptions: randomize_options,
  type: question_type,
  ...rest
}) => {
  return {
    image: image ? { id: image.id } : null,
    instant_survey_types,
    options: options.map(
      ({
        _delete,
        id,
        image,
        isAnchored: anchored,
        isExclusive: exclusive,
        isNoneOfTheAbove: none_of_the_above,
        isOther: other,
        text,
      }) => {
        return {
          _delete,
          anchored,
          exclusive,
          id: typeof id === 'string' ? undefined : id,
          image: image ? { id: image.id } : null,
          none_of_the_above,
          other,
          text,
        };
      }
    ),
    question_type,
    randomize_options,
    ...rest,
  };
};

/**
 * formatQuestionLogic
 * @param {Object} questionLogic
 * @return {Object}
 */
export const formatQuestionLogic = (questionLogic) => {
  const modifiedQuestionLogic = cloneDeep(questionLogic);
  for (const questionId in questionLogic) {
    const question = modifiedQuestionLogic[questionId];

    if (Object.keys(question).length > 0) {
      if (question.disqualify) {
        question.disqualify.answers = question.disqualify.answers.map((answer) => answer.id);
        question.disqualify.operator = question.disqualify.operator.value;

        delete question.disqualify.hasAnswerError;
        delete question.disqualify.isFirstAnswerError;
      }

      if (question.skip) {
        question.skip.operator = question.skip.operator.value;
        question.skip.logic = question.skip.logic.map((row) => {
          return {
            answers: row.answers.map((answer) => answer.id),
            operator: row.operator.value,
            question: row.question?.id,
          };
        });
      }
    } else {
      delete modifiedQuestionLogic[questionId];
    }
  }
  return modifiedQuestionLogic;
};

/**
 * transformSurveyPrompt
 * @param {Object} prompt
 * @param {Boolean} prompt.advanced
 * @param {Boolean} [prompt.allow_custom_date]
 * @param {String} prompt.display_name
 * @param {Date} [prompt.ecomm_delay_date]
 * @param {Number} [prompt.ecomm_lag_period_in_days]
 * @param {String} prompt.empty_value
 * @param {Date} [prompt.general_lag_date]
 * @param {Number} prompt.id
 * @param {String} prompt.insights_key
 * @param {String} prompt.instant_survey_type
 * @param {Boolean} prompt.is_prefix_enabled
 * @param {Number} [prompt.lag_period_in_days]
 * @param {Number} [prompt.max_values]
 * @param {Number} [prompt.min_answers]
 * @param {String} prompt.prompt_key
 * @param {Object} prompt.prompt_type
 * @param {Boolean} prompt.required
 * @param {Boolean} prompt.search_enabled
 * @param {Boolean} prompt.sort_enabled
 * @return {Object}
 */
export const transformSurveyPrompt = ({
  advanced: isAdvanced,
  allow_custom_date: isCustomDateAllowed,
  display_name: name,
  empty_value: emptyValue,
  general_lag_date: maxDate,
  id,
  insights_key: insightsKey,
  // instant_survey_type,
  is_prefix_enabled: isPrefixIncluded,
  max_values: maxValues,
  min_answers: minAnswers,
  prompt_key: promptKey,
  prompt_type: promptType,
  required: isRequired,
  search_enabled: isSearchEnabled,
  sort_enabled: isSortEnabled,
}) => {
  return {
    attributes: promptType.fields?.map(({ insights_id: id, field, name }) => ({
      id,
      field,
      name,
    })),
    ...(promptKey === 'date_range'
      ? {
          dateOptions: promptType.options?.map(
            ({ options = [], time_frame: timeFrame, tooltip }) => ({
              options: options.map(({ date_range, label }) => {
                return {
                  label,
                  value: date_range,
                };
              }),
              timeFrame,
              type: tooltip,
            })
          ),
        }
      : {}),
    emptyValue,
    id,
    insightsKey,
    isAdvanced,
    isCustomDateAllowed,
    isPrefixIncluded,
    isRequired,
    isSearchEnabled,
    isSortEnabled,
    maxDate,
    maxValues,
    minAnswers,
    name,
    ...(promptKey !== 'date_range' && promptType.options?.length > 0
      ? {
          objects: promptType.options.map(({ display: name, key: id }) => ({
            id,
            name,
          })),
        }
      : {}),
    promptKey,
  };
};

/**
 * transformImageObject
 * @param {Object} image
 * @param {String} image.bucket_name
 * @param {String} image.cdn_url
 * @param {Number} image.id
 * @param {String} image.object_key
 * @param {String} image.object_type
 * @param {String} image.url
 * @return {Object}
 */
export const transformImageObject = ({
  bucket_name: bucketName,
  cdn_url: cdnUrl,
  id,
  object_key: objectKey,
  object_type: objectType,
  url,
}) => ({
  bucketName,
  cdnUrl: cdnUrl.indexOf('http://localhost') !== -1 ? PLACEHOLDER_IMAGE : cdnUrl,
  id,
  objectKey,
  objectType,
  url,
});

/**
 * transformInstantSurvey
 * @param {Object} instantSurvey
 * @param {Date} [instantSurvey.build_quota_groups_datetime]
 * @param {Number} [instantSurvey.contact_list_ref]
 * @param {String} instantSurvey.country
 * @param {Date} instantSurvey.created_at
 * @param {Object[]} instantSurvey.groups
 * @param {Numer} instantSurvey.id
 * @param {Number} [instantSurvey.insights_survey_ref]
 * @param {String} instantSurvey.instant_survey_type
 * @param {?Date} instantSurvey.issue_datetime
 * @param {Number} [instantSurvey.job_ref]
 * @param {Object[]} instantSurvey.questions
 * @param {Object} instantSurvey.question_logic
 * @param {Number} instantSurvey.status
 * @param {Date} [instantSurvey.survey_created_datetime]
 * @param {Number} [instantSurvey.survey_ref]
 * @param {Number} [instantSurvey.survey_source_id]
 * @param {String} instantSurvey.title
 * @param {Date} instantSurvey.updated_at
 * @return {InstantSurvey}
 */
export const transformInstantSurvey = ({
  groups,
  id,
  instant_survey_type: instantSurveyType,
  issue_datetime: issueDatetime,
  questions,
  question_logic: questionLogic,
  status,
  title,
}) => {
  const transformedQuestions = questions.map(transformQuestion);
  return {
    groups: groups.map(transformInstantSurveyGroup),
    id,
    instantSurveyType,
    issueDatetime,
    questions: transformedQuestions,
    ...transformQuestionLogic({ questionLogic, questions: transformedQuestions }),
    status,
    title,
  };
};

/**
 * transformInstantSurveyGroup
 * @param {Object} group
 * @param {Number} group.id
 * @param {Array} group.prompt_answers
 * @param {Number} group.quota_limit
 * @param {?Number} group.response_count
 * @param {String} group.title
 * @param {String} validation_status
 * @param {Number} [validation_unique_users]
 * @param {Number} [validation_users]
 * @return {Object}
 */
const transformInstantSurveyGroup = ({
  id,
  prompt_answers: promptAnswers,
  quota_limit: quotaLimit,
  response_count: responseCount,
  title: name,
  validation_status: validationStatus,
  validation_unique_users: validationUniqueUsers,
  validation_users: validationUsers,
}) => ({
  expectedCompletes: validationUsers ? Math.floor(validationUsers / 20) * 10 : null,
  id,
  name,
  promptAnswers: promptAnswers.map(
    ({ fe_notes: feNotes, field, field_type: fieldType, id, operator, prompt_id: promptId }) => ({
      field,
      fieldType,
      id,
      operator,
      promptId,
      values: feNotes?.insights_values,
    })
  ),
  quotaLimit,
  responseCount,
  validationStatus,
  validationUniqueUsers,
  validationUsers,
});

/**
 * @typedef {Object} InstantSurveyType
 * @property {Object[]} advancedPrompts
 * @property {String} description
 * @property {String} helpUrl
 * @property {String[]} hierarchy_search_prompts
 * @property {Number} id
 * @property {String} madlib
 * @property {String} name
 * @property {Object[]} prompts
 */

/**
 * transformInstantSurveyType
 * @param {Object} surveyType
 * @param {String} surveyType.description
 * @param {String} [surveyType.help_url]
 * @param {String} surveyType.id
 * @param {String} surveyType.label
 * @param {String} surveyType.name
 * @param {Object[]} surveyType.prompts
 * @param {String} surveyType.prompts_madlib
 * @return {InstantSurveyType}
 */
export const transformInstantSurveyType = ({
  description,
  help_url: helpUrl = SURVEY_HELP_URL,
  hierarchy_search_prompts,
  id,
  label,
  name,
  prompts,
  prompts_madlib: madlib,
}) => {
  return {
    advancedPrompts: prompts
      .filter(({ advanced, prompt_key }) => advanced && prompt_key !== 'images') // images is still TBD
      .map(transformSurveyPrompt),
    description,
    helpUrl,
    hierarchy_search_prompts,
    id,
    label,
    madlib,
    name,
    prompts: prompts
      .filter(({ advanced, prompt_key }) => !advanced && prompt_key !== 'images')
      .map(transformSurveyPrompt),
  };
};

/**
 * transformInstantSurveyTypes
 * @param {Object} response
 * @param {Array} response.results
 * @return {Object}
 */
export const transformInstantSurveyTypes = ({ results, ...rest }) => {
  return {
    results: results.map((surveyType) => transformInstantSurveyType(surveyType)),
    ...rest,
  };
};

/**
 * transformQuestion
 * @param {Object} question
 * @param {String} question.caption
 * @param {Date} question.created_at
 * @param {Boolean} [question.deleted]
 * @param {Number} question.folder
 * @param {Number} question.id
 * @param {Object} [question.image]
 * @param {Number} [question.insights_question_ref]
 * @param {String[]} question.instant_survey_types
 * @param {String} question.model_type
 * @param {Object[]} question.options
 * @param {Object} [question.parameters]
 * @param {String} question.question_type
 * @param {Boolean} question.randomize_options
 * @param {Boolean} question.survey_question
 * @param {String} question.text
 * @param {String} [question.title]
 * @param {String} question.type
 * @param {Date} question.updated_at
 * @return {Question}
 */
export const transformQuestion = ({
  caption,
  folder,
  id,
  instant_survey_types: instantSurveyTypes,
  image,
  options,
  question_type: type,
  randomize_options: randomizeOptions,
  text,
}) => ({
  answers: options.map(
    ({
      anchored: isAnchored,
      exclusive: isExclusive,
      id,
      image,
      none_of_the_above: isNoneOfTheAbove,
      other: isOther,
      text,
    }) => ({
      id,
      image: image ? transformImageObject(image) : null,
      isAnchored,
      isExclusive,
      isNoneOfTheAbove,
      isOther,
      text,
    })
  ),
  caption,
  folder,
  id,
  image: image ? transformImageObject(image) : null,
  instantSurveyTypes,
  randomizeOptions,
  text,
  type,
});

/**
 * transformQuestionFolder
 * @param {Object} folder
 * @param {Object[]} folder.contents
 * @param {Number} folder.count
 * @param {Object} folder.current_questionfolder
 * @param {Number} folder.limit
 * @param {Number} folder.offset
 * @param {Object} [folder.parent_folder]
 * @return {Object}
 */
export const transformQuestionFolder = ({ contents, count, limit, offset }) => ({
  contents: contents.map((content) => {
    switch (content.type) {
      case MODEL_TYPES.QUESTION:
        return transformQuestion(content);
      case MODEL_TYPES.QUESTION_FOLDER:
        // `isFolder` still necessary while using existing ModalQuestionsLibrary/components/Results
        return { ...content, isFolder: true, text: content.title };
      default:
        return content;
    }
  }),
  count,
  limit,
  offset,
});

/**
 * transformQuestionLogic
 * @param {Object} options.questionLogic
 * @param {Question[]} options.questions
 * @return {Object}
 */
export const transformQuestionLogic = ({ questionLogic, questions }) => {
  let hasQuestionLogicError = false;
  const modifiedQuestionLogic = cloneDeep(questionLogic);
  for (const questionId in modifiedQuestionLogic) {
    const question = modifiedQuestionLogic[questionId];
    if (question.disqualify) {
      question.disqualify.operator = QUESTION_LOGIC_OPERATORS[question.disqualify.operator];
      const disqualifyQuestion = questions.find((question) => question.id === parseInt(questionId));
      const initialAnswersLength = question.disqualify.answers.length;
      question.disqualify.answers = question.disqualify.answers
        .map((answerId) => {
          return disqualifyQuestion.answers.find((answer) => answer.id === answerId);
        })
        .filter((answer) => !!answer);
      if (
        question.disqualify.answers.length === 0 ||
        question.disqualify.answers.length !== initialAnswersLength
      ) {
        hasQuestionLogicError = true;
      }
    }
    if (question.skip) {
      question.skip.operator = QUESTION_LOGIC_OPERATORS[question.skip.operator];
      // eslint-disable-next-line no-loop-func
      question.skip.logic = question.skip.logic.map((row) => {
        const skipQuestion = questions.find((question) => question.id === row.question);
        const answers = row.answers
          .map((answerId) => {
            return skipQuestion.answers.find((answer) => answer.id === answerId);
          })
          .filter((answer) => !!answer);
        if (answers.length === 0 || answers.length !== row.answers.length) {
          hasQuestionLogicError = true;
        }

        return {
          answers,
          operator: QUESTION_LOGIC_OPERATORS[row.operator],
          question: skipQuestion,
        };
      });
    }
  }
  return { hasQuestionLogicError, questionLogic: modifiedQuestionLogic };
};
