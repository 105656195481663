import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import classNames from 'classnames';

import { CheckboxIcon, CheckboxFilledIcon, CheckboxPartialIcon } from '../../icons';

import styles from './_index.module.scss';

const Checkbox = ({
  'data-testid': dataTestId = 'checkbox',
  id = nanoid(),
  isChecked,
  isDisabled,
  isPartial,
  label,
  onChange,
  title,
  value,
}) => {
  const handleChange = () => {
    if (!isDisabled) {
      onChange(value);
    }
  };

  const renderCheckboxIcon = () => {
    if (isChecked) return <CheckboxFilledIcon />;
    if (isPartial) return <CheckboxPartialIcon />;
    return <CheckboxIcon />;
  };

  return (
    <div
      className={classNames(styles['checkbox'], {
        [styles['is-checked']]: isChecked,
        [styles['is-partial']]: isPartial,
      })}
      data-testid={dataTestId}
    >
      <input id={id} onChange={handleChange} type="checkbox" />
      <label
        className={classNames(styles['checkbox-label'], {
          [styles['is-disabled']]: isDisabled,
        })}
        htmlFor={id}
        title={title}
      >
        <div className={styles['checkbox-icon']}>{renderCheckboxIcon()}</div>
        {label}
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  onChange: () => {},
};

Checkbox.propTypes = {
  'data-testid': PropTypes.string,
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPartial: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChange: PropTypes.func,
  title: PropTypes.string,
  value: PropTypes.any.isRequired,
};

export default Checkbox;
